@import "./variables";

.text-xs {
  font-size: $entromy-font-size-xs;
}

.text-s {
  font-size: $font-size-sm;
}

.text-m {
  font-size: $font-size;
}

.text-l {
  font-size: $font-size-lg;
}

.text-h4 {
  font-size: $font-size-heading-4;
}

.text-h3 {
  font-size: $font-size-heading-3;
}

.text-h2 {
  font-size: $font-size-heading-2;
}

.text-h1 {
  font-size: $font-size-heading-1;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.text-normal {
  font-weight: normal;
}

.color-primary {
  color: $color-primary;
}

.color-info {
  color: $color-info;
}

.color-warning {
  color: $color-warning;
}

.color-primary-dark {
  color: $color-primary-active; // old $primary-dark-color
}

.color-error {
  color: $color-error;
}

.color-text {
  color: $color-text !important;
}

.color-text-secondary {
  color: $color-text-secondary !important;
}

.color-text-description {
  color: $color-text-description !important;
}

.color-text-base {
  color: $color-text-base !important;
}

.color-secondary-dark {
  color: $color-text;
}

.color-disabled {
  color: $color-text-disabled;
}

.color-text-tertiary {
  color: $color-text-tertiary;
}

.color-light {
  color: $color-text-light-solid;
}

.color-bg-layout {
  background-color: $color-bg-layout !important;
}

.color-icon {
  color: $color-icon;
}

@for $x from -50 through 100 {
  .m-#{$x} {
    margin: $x + px !important;
  }
  .mx-#{$x} {
    margin-left: $x + px !important;
    margin-right: $x + px !important;
  }
  .ml-#{$x} {
    margin-left: $x + px !important;
  }
  .mr-#{$x} {
    margin-right: $x + px !important;
  }
  .my-#{$x} {
    margin-top: $x + px !important;
    margin-bottom: $x + px !important;
  }
  .mt-#{$x} {
    margin-top: $x + px !important;
  }
  .mb-#{$x} {
    margin-bottom: $x + px !important;
  }
  .p-#{$x} {
    padding: $x + px !important;
  }
  .px-#{$x} {
    padding-left: $x + px !important;
    padding-right: $x + px !important;
  }
  .pl-#{$x} {
    padding-left: $x + px !important;
  }
  .pr-#{$x} {
    padding-right: $x + px !important;
  }
  .py-#{$x} {
    padding-top: $x + px !important;
    padding-bottom: $x + px !important;
  }
  .pt-#{$x} {
    padding-top: $x + px !important;
  }
  .pb-#{$x} {
    padding-bottom: $x + px !important;
  }
}

@for $x from 1 through 24 {
  .flex-#{$x} {
    flex: $x;
  }
}

.w-m {
  width: $screen-md-max !important;
}

.w-100 {
  width: 100% !important;
}

.w-30 {
  width: 100% !important;
}

.w-110 {
  width: 110% !important;
}

.h-100 {
  height: 100% !important;
}

.h-90 {
  height: 90% !important;
}

.w-modal-m {
  width: 400px !important;
}

.w-modal-l {
  width: 500px !important;
}

.w-sm-min {
  width: $screen-sm-min;
}

.hidden {
  display: none !important;
}

.inline-block {
  display: inline-block !important;
}

.display-block {
  display: block !important;
}

.no-wrap {
  flex-wrap: nowrap !important;
}

.text-nowrap {
  white-space: nowrap;
}

.white-space-normal {
  white-space: normal;
}

.pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.shadow {
  box-shadow: $box-shadow !important;
}

.shadow-secondary {
  box-shadow: $box-shadow-secondary !important;
}

.shadow-tertiary {
  box-shadow: $box-shadow-tertiary !important;
}

.no-shadow {
  box-shadow: none !important;
}

.no-bg {
  background: none !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.hoverable:hover {
  opacity: $opacity-loading;
}

.white-space-normal {
  white-space: normal;
}

.bordered {
  border: 1px solid $color-border;
  border-radius: calc($border-radius / 2);
}

.border-split-right {
  border-right: 1px solid $color-split;
}

.pass-pointer-events {
  pointer-events: none;
}

.transparent {
  opacity: 0;
}

.transparent-border {
  border: 1px solid transparent;
}

.fw-400 {
  font-weight: 400;
}

@import "./variables";
@import "antd/dist/reset.css";
@import "./utils";

html,
body {
  height: 100%;
  width: 100%;
}

body {
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  margin: 0;
  background-color: $entromy-color-bg;
}

mark {
  font-weight: bold;
  background-color: transparent;
  padding: 0;
  color: inherit;
}

.ant-disabled,
.ant-empty,
.ant-empty-description {
  color: $color-text-disabled !important;
}

.ant-table .ant-table-thead > tr > th {
  font-weight: bold;
}

.ant-typography mark {
  background-color: transparent;
}

.ant-dropdown-menu .ant-dropdown-menu-item > a,
.ant-dropdown-menu .ant-dropdown-menu-item,
.ant-dropdown-menu .ant-dropdown-menu-submenu-title {
  padding: 5px 20px;

  &:hover {
    background-color: $color-primary !important;
    color: $color-text-light-solid;

    .ant-dropdown-menu-title-content * {
      color: $color-text-light-solid !important;
    }
  }
}

.ant-dropdown .ant-dropdown-menu-item,
.ant-dropdown .ant-dropdown-menu-submenu {
  &:hover {
    .ant-checkbox-wrapper + span {
      color: $control-item-bg-hover;
    }
  }
}

.ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-selected {
  background-color: $color-primary-active;
  color: $color-text-light-solid;
}

.ant-dropdown .ant-dropdown-menu-item.ant-dropdown-menu-item-selected {
  .ant-checkbox-wrapper + span {
    color: $color-text-light-solid;
  }
}

.ant-dropdown-menu-title-content > a {
  transition-duration: 0s !important;
}

.ant-table-summary {
  .ant-table-cell {
    border-bottom: none;
  }
}

.ant-dropdown-menu .ant-dropdown-menu-item > a {
  margin: -5px -20px;
}

.ant-modal-header .ant-modal-title {
  font-weight: bold;
}

.ant-result.ant-result-success .ant-result-icon > .anticon {
  color: $color-primary;
}

.ant-form .ant-form-item {
  margin-bottom: 15px;
}

.ant-avatar {
  opacity: 1 !important;
  background: $color-text-placeholder;
}

div.ant-typography {
  margin-bottom: 0;
}

.hint-text {
  color: $color-text;
  font-size: $font-size-sm;
}

.no-auth-page {
  background-image: url(./assets/images/coffee-table.jpg);
  background-size: cover;

  .page__body {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .no-auth-page__form-wrapper {
    text-align: center;
    width: 300px;
    padding: 20px;

    .ant-form {
      margin-top: 20px;
    }
  }
}

.btn-icon {
  margin-right: 5px;
}

.relative {
  position: relative;
}

.hide-errors {
  .ant-form-item-has-error .ant-form-item-explain {
    display: none;
  }
}

.dropdown-wrap-lines {
  .ant-select-item-option-content {
    white-space: normal;
  }
}

.no-access-report {
  text-align: center;
  font-style: italic;
  font-weight: bold;
}

.ant-affix {
  .affix-hidden {
    display: none;
  }
}

.login-content {
  h3 {
    color: $color-text;
    font-weight: bold;
  }

  > div {
    color: $color-text;
  }

  .login-content__links a {
    font-size: $font-size-sm;
  }
}

.ant-dropdown-menu-item {
  &:hover {
    .ant-upload {
      color: $color-text-light-solid;
    }
  }
}

.users-dropdown {
  .users-dropdown__load-more {
    padding: 5px 12px;
    text-align: center;
  }

  .users-dropdown__search {
    padding: 5px 12px;
  }

  .search-input {
    margin-bottom: 0;
  }
}

.wizard {
  .wizard__tab {
    min-height: 500px;

    .wizard__tab__title {
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 18px; // TODO: do we need this? or we have to override ant variable? fontSizeHeading5

      .wizard__tab__subtitle {
        font-weight: normal;
        font-size: $font-size;
      }
    }
  }

  .access__add-select {
    position: relative;

    .ant-select {
      z-index: 1;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      position: absolute;

      :hover {
        cursor: pointer;
      }
    }

    .ant-select-selector {
      height: 32px;
      padding: 0;
      border: none;
      background: transparent !important;

      .ant-select-selection-search,
      .ant-select-selection-overflow {
        display: none;
      }
    }
  }

  .wizard__add-select {
    position: relative;

    .ant-select {
      z-index: 1;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      position: absolute;

      .ant-select-selector {
        height: 32px;
        padding: 0;
        border: none;
        background: transparent !important;

        .ant-select-selection-search,
        .ant-select-selection-overflow {
          display: none;
        }
      }

      &:not(.ant-select-disabled) .ant-select-selector {
        cursor: pointer !important;
      }
    }
  }
}

.row-dragging {
  .drag-hidden {
    opacity: 0;
  }
}

.ant-btn-icon-only {
  vertical-align: 0;
}

.table-editable {
  tbody tr {
    cursor: pointer;
  }
}

.multiselect-short {
  .ant-select-selection-overflow-item:first-child div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-select-selection-overflow-item-rest,
  .ant-select-selection-overflow-item-suffix {
    display: none;
  }

  &__value {
    padding: 0 0 0 6px;
  }
}

.ant-card-flex {
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
}

// fix for ResizeObserver loop completed with undelivered notifications
// https://stackoverflow.com/a/77094068
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.no-hover-effect .ant-table-cell {
  &.ant-table-cell-row-hover {
    background-color: transparent !important;
  }

  border-bottom: none !important;
}

.ant-btn {
  box-shadow: none !important;
}

.ant-notification {
  .ant-btn-link,
  .ant-btn-link * {
    color: $entromy-color-primary;

    :hover {
      color: $entromy-color-link-hover;
    }
  }
}

.ant-message-loading {
  .anticon-loading {
    color: $entromy-color-primary !important;
  }
}

// Temp fix for CKeditor, remove in PM-1611
.cke_notifications_area {
  display: none !important;
}

.ai-button {
  background-color: $entromy-ai-color;

  &:not([disabled]):hover {
    background-color: $entromy-ai-color-hover !important;
  }
}

.dashboard-regular {
  .h-row1 {
    height: 260px !important;
  }

  .h-row2 {
    height: 438px !important;
  }
}

.primary-text {
  color: $color-primary;
}

// and fix for flickering vertical tabs: TODO: remove after antd upgrade to 5.22.2+
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
  all: revert;
}

.logo {
  width: auto;
  height: 27px;
}

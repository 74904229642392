@import "../../variables";

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $color-bg-base;
  z-index: 10;

  .navbar__caret {
    margin-bottom: 2px;
  }

  .navbar__content {
    max-width: 1440px;
    padding: 0 40px;
    width: 100%;
    margin: 0 auto;
  }

  .navbar__logo__wrapper {
    min-width: 150px;
  }

  .navbar__logo {
    height: 36px;
    width: auto;
    max-width: 220px;
    object-fit: contain;
    margin: 10px 0;
  }

  .navbar__menu__item {
    text-align: center;
    position: relative;
    color: $color-text;
    overflow: visible;
    height: 56px;
    display: flex;
    align-items: center;
    padding: 3px 0;
    border-bottom: 2px solid transparent;

    &.ant-dropdown-open:not(.navbar__menu__item--avatar) {
      border-bottom: 2px solid $color-primary;
    }

    &.navbar__menu__item--big {
      width: 40px;
      height: 40px;
      justify-content: center;

      &:hover,
      &.ant-dropdown-open {
        background-color: $button-text-hover-bg !important;
        border-bottom: 2px solid transparent;
      }

      .anticon {
        font-size: 20px;
        margin-right: 0;
      }
    }

    .anticon {
      margin-right: 8px;
      font-size: 16px;
    }

    &:hover {
      color: $color-text;
      background-color: transparent !important;
      border-bottom: 2px solid $color-primary;

      &.navbar__menu__item--avatar {
        border-bottom: 2px solid transparent;
      }
    }

    &.navbar__menu__item--active {
      color: $color-primary;
      border-bottom: 2px solid $color-primary;

      &:hover {
        color: $color-primary;
      }

      &.navbar__menu__item--big {
        border-bottom: 2px solid transparent;
      }

      .ant-badge {
        color: $color-primary;
      }
    }

    .navbar__menu__item__badge {
      position: absolute;
      color: $color-bg-base;
      background-color: $color-primary;
      padding: 2px 5px;
      right: 0;
      top: -4px;
      font-size: 11px;
      line-height: 13px;
      border-radius: 2px;
    }
  }
}

.navbar__submenu {
  min-width: 150px;

  .ant-dropdown-menu-submenu-arrow {
    display: none;
  }
}

.navbar_orgs-submenu ul {
  width: 300px;
  max-height: 300px;
  overflow-y: auto;

  .navbar_orgs-submenu__item--active {
    background-color: $color-primary-active;
    color: $color-bg-base;
  }
}

.ant-dropdown-menu .ant-dropdown-menu-item.search-menu-item {
  .search-input {
    margin-bottom: 0;
  }

  &:hover {
    background: transparent;
  }
}

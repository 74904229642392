@import "../../variables";

.color-disabled .ant-statistic .ant-statistic-content {
  color: $color-text-disabled;
}

.vi__container .vi__character {
  border-color: $color-border;
  border-radius: 3px;
}

.color-error .vi__container .vi__character {
  border-color: $color-error;
}

.user-avatar.ant-avatar.ant-avatar-circle.active,
.user-avatar.ant-avatar.ant-avatar-circle:hover {
  border-color: $color-primary-active;
}
